/*
  make profile object conform to a set of expectations
  in the interest of avoiding javascript errors
*/

// important properties that aren't here:
// 'id': gets put into profile when fetched
// 'ownerUID', gets put into profile when first created
const emptyProfile = {
  personalDetails: {
    firstName: '',
    lastName: '',
    jobTitle: '',
    phone: '',
    email: ''
  },
  professionalSummary: '',
  websitesAndSocial: [],
  employmentHistory: [],
  education: [],
  skills: [],
  metadata: {
    name: 'Untitled',
    theme: '',
    previewURL: '',
  }
}

const makeSafeProfile = (profile) => {
  // profile should be an object
  // it should have all expected keys
  // and some of those keys should have keys as well
  const safeProfile = {...emptyProfile, ...profile}
  const withSafeDates = profileWithSafeDates(safeProfile)
  return withSafeDates
}

// firestore converts dates into timestamps
// so we want to turn them back

// takes in possible timestamp value in need of conversion to date
// converts to date if it needed
const convertPossibleTimestampToDate = (possibleTimestamp) => {
  if (possibleTimestamp && typeof possibleTimestamp.toDate === 'function') {
    return possibleTimestamp.toDate()
  }
  return possibleTimestamp
}

const profileWithSafeDates = (profile) => {
  const newProfile = {...profile}
  newProfile.employmentHistory.forEach((item) => {
    item.startDate = convertPossibleTimestampToDate(item.startDate)
    item.endDate = convertPossibleTimestampToDate(item.endDate)
  })
  newProfile.education.forEach((item) => {
    item.startDate = convertPossibleTimestampToDate(item.startDate)
    item.endDate = convertPossibleTimestampToDate(item.endDate)
  })
  return newProfile
}


export {makeSafeProfile}
