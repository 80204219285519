import firebaseAppLib from 'firebase/app';
import 'firebase/firestore'
import getFirebase from 'src/utilities/firebase'; // import our getFirebase function
import {makeSafeProfile} from 'src/utilities/safe-profile'

// create a resume / profile object from firebase document / query result
const createResumeFromFirebaseDocument = (doc) => {
  return {
    ...makeSafeProfile(doc.data()),
    id: doc.id,
  }
}

const getResumesCollection = () => {
  const firebase = getFirebase()
  if (!firebase) return
  if (!window) return
  const db = firebase.firestore()
  if (window.location.hostname === "localhost") {
    db.useEmulator("localhost", 8080);
  }
  return db.collection('resumes')
}

const getReviewsCollection = () => {
  const firebase = getFirebase()
  if (!firebase) return
  if (!window) return
  const db = firebase.firestore()
  if (window.location.hostname === "localhost") {
    db.useEmulator("localhost", 8080);
  }
  return db.collection('reviews')
}

// takes in resume id and returns resume document wrapped in promise
const getResume = ({resumeId}) => {
  const resumesCollection = getResumesCollection()
  if (!resumesCollection) return Promise.reject()

  return resumesCollection.doc(resumeId).get()
      .then((doc) => {
        if (doc.exists) {
          return createResumeFromFirebaseDocument(doc)
        }
        throw new Error(`Resume with id: ${resumeId} does not exist`, {code: 'not-found'})
      })
}

// takes in resume id and resume object and sets remote resume
// WARNING: generally it is safer to use updateResume to avoid
// accidentally deleting data
const setResume = ({resumeId, resume}) => {
  const resumesCollection = getResumesCollection()
  if (!resumesCollection) return Promise.reject()

  return resumesCollection.doc(resumeId).set({
    ...resume,
    updatedAt: firebaseAppLib.firestore.FieldValue.serverTimestamp(),
  })
}

// pass in resumeId and updates object
// updates looks like {'personalDetails.name': 'frank'} to update resume
// optional argument bypassUpdatedAt allows you to update without updating updatedAt
// eg in case of updates not initiated by user
const updateResume = ({resumeId, updates, bypassUpdatedAt}) => {
  const resumesCollection = getResumesCollection()
  if (!resumesCollection) return Promise.reject()

  if (!bypassUpdatedAt) {
    updates.updatedAt = firebaseAppLib.firestore.FieldValue.serverTimestamp()
  }
  return resumesCollection.doc(resumeId)
  .update(updates)
}
// creates resume
// will set
// ownerUID, createdAt, and updatedAt
// can optionally pass in a resume object as resumeInfo
// if copying, probably want to remove or change certain fields like
// id, metadata.name, etc
// createdAt, updatedAt, ownerUID will be overwritten
const createResume = ({ownerUID, resumeInfo}) => {
  const resumesCollection = getResumesCollection()
  if (!resumesCollection) return Promise.reject()

  const createdAt = firebaseAppLib.firestore.FieldValue.serverTimestamp()
  const updatedAt = createdAt
  return resumesCollection.add({
    ...makeSafeProfile(resumeInfo),
    ownerUID,
    createdAt,
    updatedAt,
  })
}
// orderBy can be a field name, or an array with a field name and 'desc'. 'asc' is default.
const getResumes = ({ownerUID, orderBy, limit}) => {
  const resumesCollection = getResumesCollection()
  if (!resumesCollection) return Promise.reject()

  let query = resumesCollection.where("ownerUID", "==", ownerUID)

  // https://firebase.google.com/docs/firestore/query-data/order-limit-data
  if (orderBy && Array.isArray(orderBy)) {
    // can take multiple args, for field, and 'asc' or 'desc'
    query = query.orderBy(...orderBy)
  } else if (orderBy) {
    // or just the field
    query = query.orderBy(orderBy)
  }
  if (limit) {
    query = query.limit(limit)
  }

  return query.get()
  .then(snapshot => {
    return snapshot.docs.map(doc => createResumeFromFirebaseDocument(doc))
  })
}

const copyResume = ({ownerUID, resumeId,}) => {
  return getResume({resumeId})
    .then(resume => {
      const resumeCopy = {
        ...resume,
        id: null,
        metadata: {
          ...resume.metadata,
          name: `Copy of ${resume.metadata.name}`
        }
      }
      return createResume({
        ownerUID,
        resumeInfo: resumeCopy,
      })
    })
}

const deleteResume = ({resumeId}) => {
  const resumesCollection = getResumesCollection()
  if (!resumesCollection) return Promise.reject()

  return resumesCollection.doc(resumeId).delete()
}

const createReview = ({ownerUID, ratingValue, text, name}) => {
  const reviewsCollection = getReviewsCollection()
  if (!reviewsCollection) return Promise.reject()

  const createdAt = firebaseAppLib.firestore.FieldValue.serverTimestamp()
  const updatedAt = createdAt
  return reviewsCollection.add({
    ownerUID,
    createdAt,
    updatedAt,
    ratingValue,
    text,
    name,
  })
}

// queries for reviews with ownerUID and name
// returns promise resolving in boolean value indicating whether review exists
const doesReviewExist = ({ownerUID, name}) => {
  const resumesCollection = getReviewsCollection()
  if (!resumesCollection || !ownerUID || !name) return Promise.reject()

  let query = resumesCollection
                .where("ownerUID", "==", ownerUID)
                .where('name', '==', name)

  return query.get()
  .then(snapshot => {
    return !snapshot.empty
  })
}

export {
  getResume, updateResume, createResume, getResumes, setResume, copyResume, deleteResume,
  createReview, doesReviewExist,
}
